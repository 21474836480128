













































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ItemCard extends Vue {
  @Prop() item

  showOverlay = false

  openModal() {
    this.$root.$emit('openShopItemModal', this.item)
  }
}
